import { avalibeRouter } from '~/stores/avalibeRouter';

export default defineNuxtRouteMiddleware(to => {
    let pageParam = to.params?.page;
    pageParam = Array.isArray(pageParam) ? pageParam[0] : pageParam;
    if (pageParam === undefined) {
        return false;
    }
    const routeStore = avalibeRouter();
    return routeStore.routes.map(({ url }) => url).includes(pageParam);
});
